<template>
  <div v-if="show" class="d-flex flex-column flex-fill white pb-5 report">
    <div class="text-center">
      <h1>Comprovante</h1>
      <h3>Fisiopower</h3>
      <span> <strong>CNPJ: </strong>21.267.979/0001-39 </span><br />
      <span> <strong>Telefone: </strong>33287917 </span><br />
      <span><strong>Endereço: </strong>Rua Deputado Nilson Ribas, nº 987</span>
    </div>
    <hr />
    <div class="d-flex justify-space-around my-5">
      <span> <strong>Nº: </strong>{{ sale.id }} </span>
      <span> <strong>Vendedor: </strong>{{ sale.salesman.name }} </span>
      <span>
        <strong>Data: </strong>
        {{ sale.date | moment("DD/MM/YYYY hh:mm:ss") }}
      </span>
    </div>
    <hr />
    <div class="client my-5 mx-10">
      <div class="colspan">
        <strong>Cliente: </strong>{{ sale.client && sale.client.name }}
      </div>
      <div>
        <strong>CNPJ/CPF: </strong
        >{{ sale.client.cnpj.length ? sale.client.cnpj : sale.client.cpf }}
      </div>
      <div><strong>Fone: </strong>{{ sale.client.phone }}</div>
      <div class="colspan">
        <strong>Endereço: </strong>{{ sale.client.street }}
      </div>
      <div><strong>Complemento: </strong>{{ sale.client.complement }}</div>
      <div><strong>Cidade: </strong>{{ sale.client.municipality }}</div>
    </div>
    <hr />
    <div class="my-5 mx-10">
      <table>
        <thead>
          <tr>
            <th>Produto</th>
            <th>Qtd.</th>
            <th>Valor Unitário</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item of sale.products" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.amount }}</td>
            <td>{{ item.unitary_value | money }}</td>
            <td>{{ (item.amount * item.unitary_value) | money }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colspan="3" class="text-right pr-2">
              <strong>Subtotal:</strong>
            </td>
            <td>{{ totalProducts | money }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
    <hr />
    <div class="d-flex my-5 mx-10">
      <div class="col-6">
        <h3 class="d-print-none">Observação:</h3>
        <p class="d-print-none">{{ sale.obs }}</p>
        &nbsp;
      </div>
      <div class="col-6 d-flex flex-column align-end">
        <span> <strong>Acréscimo: </strong> {{ sale.rate | money }} </span>
        <span> <strong>Frete: </strong> {{ sale.freight | money }} </span>
        <span> <strong>Guia ST: </strong> {{ sale.guia_st | money }} </span>
        <span> <strong>Desconto: </strong> {{ sale.discount | money }} </span>
        <span> <strong>Total: </strong> {{ sale.total | money }} </span>
        <span> <strong>Troco: </strong> {{ sale.refund | money }} </span>
      </div>
    </div>
    <hr />
    <div class="d-flex justify-space-between mx-10 my-5">
      <span>
        <strong>Transportadora:</strong><br />{{
          sale.carrier && sale.carrier.name
        }}
      </span>
      <table class="payments">
        <thead>
          <tr>
            <th colspan="3" class="text-center">Formas de Pagamento</th>
          </tr>
          <tr>
            <th>Tipo</th>
            <th>Data</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) of sale.payments" :key="i">
            <td>{{ item.name }}</td>
            <td>{{ item.date | moment("DD/MM/YYYY") }}</td>
            <td>{{ item.value | money }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr />
    <div class="d-flex justify-space-between mx-10 signature">
      <p>{{ sale.salesman && sale.salesman.name }}</p>
      <p>{{ sale.client && sale.client.name }}</p>
    </div>
  </div>
</template>

<script>
import snackbar from "../../_mixins/snackbar";

export default {
  name: "SalePayment",
  mixins: [snackbar],
  data() {
    return {
      show: false,
      sale: {},
    };
  },
  async beforeMount() {
    const { id, request_id } = this.$route.params;
    const url = !request_id ? `/sale/${id}` : `/sale/request/${request_id}`;
    try {
      const { data } = await this.$axios(url);
      this.sale = data;
      this.show = true;
    } catch ({
      response: {
        data: { error },
      },
    }) {
      this.snackError(error);
      this.$router.push("/app/pdv");
    }
  },
  computed: {
    totalProducts() {
      const totals = this.sale.products.map((i) => i.amount * i.unitary_value);
      return totals.reduce((a, c) => a + c, 0);
    },
  },
};
</script>

<style scoped>
hr {
  border: 1px solid #f2f2f2;
}
.client {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.client .colspan {
  grid-column: span 2;
}

table {
  width: 100%;
  border-collapse: collapse;
}
thead {
  background-color: #333;
  color: #ccc;
}

thead th {
  padding: 3px 0;
}
tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
td {
  padding: 3px 0;
  text-align: center;
}
table.payments {
  width: 450px;
}
.signature {
  margin: 100px 0 0 0;
}
.signature p {
  text-align: center;
  border-top: 1px solid black;
  width: 50%;
}
.signature p + p {
  margin-left: 100px;
}
</style>
